<template>
	<div class="details">
		<!-- <Selectshop
		 :proData="transmitData"
		  /> -->
		<div class="conten_body">  
			<h3 class="consu_title"><a href="/">首页</a> > 热点资讯</h3>	
		   <div class="tab">
      <div class="tab_nav">
        <div class="item">
          <span class="title">内容分类：</span>
          <span  :class="['category',pageup?'':'more']">
            <span class="info"><a href=""><div>全部文章</div></a></span>
            <a href="" class="positionLink">
            	<span style="color:#333333;" class="fontColor">等保2.0</span>
            </a>
            <a href="" class="positionLink">
            	<span style="color:#333333;">等级保护</span>
            </a>
             <a href="" class="positionLink">
            	<span style="color:#333333;">风险评估</span>
            </a>
             <a href="" class="positionLink">
            	<span style="color:#333333;">网络安全</span>
            </a>
             <a href="" class="positionLink">
            	<span style="color:#333333;">个人信息安全</span>
            </a>
             <a href="" class="positionLink">
            	<span style="color:#333333;">渗透测试</span>
            </a>
             <a href="" class="positionLink">
            	<span style="color:#333333;">漏洞扫描</span>
            </a>
            <a href="" class="positionLink">
            	<span style="color:#333333;">信息系统安全</span>
            </a>
            <a href="" class="positionLink">
            	<span style="color:#333333;">安全外包</span>
            </a>

          </span>
          <button @click="btns" class="pkUp" v-if="pageup">收起<span class="el-icon-arrow-up"></span></button>
          <button  @click="btns" class="pkAn" v-if="!pageup">展开<span class="el-icon-arrow-down"></span></button>
        </div>
      </div>
    </div>
		<div class="conten_left">
        <ul class="contentUl">
        	<li v-for="(item,index) in fetchData" :key="index" class="contentLi">
        		<router-link target="_blank"  class="conten_a" :to="item.url">
	            <div class="itemImgBox">
	              <img :src="item.icon" alt="" class="itemImg">
	            </div>
	            <div class="itemInfo">
	              <div class="itemTopInfo">
	                <p  class="itemTitle1">{{item.title}}</p>
	                <p></p>
	              </div>
	              <div class="itemInfoContent">{{item.content}}</div>
	              <span class="itemCreatTime"><span>{{item.time}}</span></span>
	              <span class="item_art">{{item.author}}</span>
	              <span class="vp"><i class="el-icon-view"></i>{{item.vp}}</span>
	            </div>
	           </router-link>
	         </li>

            </ul>
             <el-pagination
              class="pagestyle"
		      @size-change="handleSizeChange"
		      @current-change="handleCurrentChange"
		      :current-page="currentPage4"
		      :page-sizes="[10, 30, 50, 100]"
		      :page-size="10"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="4">
		    </el-pagination>

			</div>
			<!-- right -->
			<Hotgoods  :contant="fetching"/>
			
		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
// import Selectshop from "@/components/Selectshop.vue"

export default{
components:{
      Hotgoods
      // Selectshop
	},
data(){
	return{
		fetching:false,
		currentPage4: 1,
		pageup:false,
		fetchData:[
		{
			url:'/consultDetail',
			icon:require('../../assets/images/a05.jpg'),
			title:'工信部：加强网络安全风险隐患排查，做好行业关键信息基础设施安全防护',
			content:'10月12日，工信部发布《关于做好2020年电信和互联网行业网络安全检查工作的通知》(以下简称《通知》)，《通知》称，将围绕加快推进网络强国建设战略目标，加强网络安全风险隐患排查，通报检查结果并加大整改力度，切实做好行业关键信息基础设施安全防护，保障电信网和公共互联网的持续安全稳定运行。',
			time:'2020-11-05',
			author:'第一财经',
			vp:255 
		},
		{
			url:'/consultDetail1',
			icon:require('../../assets/images/a08.jpg'),
			title:'等保2.0和等保1.0分别有哪些标准文件',
			content:'等级保护在2007年正式启动，而在2019年12月1日等保2.0正式实施，意味着等级保护正式进入等保2.0时代。因此，等级保护的相关标准也得到了完善，逐步采用等保2.0的标准来实施等级保护相关工作。等保2.0和等保1.0分别有哪些标准文件呢。',
			time:'2020-11-05',
			author:'网络整理',
			vp:255 
		},
		{
			url:'/consultDetail2',
			icon:require('../../assets/images/a06.jpg'),
			title:'等级保护和网络安全法的关系？',
			content:'等级保护工作是国家网络安全的基础性工作，是网络安全法要求我们履行的一项安全责任。网络安全法是网络安全领域的基本法，从国家层面对等级保护工作的法律认可，网络安全法中明确的提到信息安全的建设要遵照等级保护标准来建设。',
			time:'2019-08-21',
			author:'万方科技整理',
			vp:255 
		},
		{
			url:'/consultDetail3',
			icon:require('../../assets/images/a07.jpg'),
			title:'周晓峰：什么是信息安全风险评估',
			content:'依据国家信息安全标准《信息安全技术 信息安全风险评估规范》(GB/T 20984-2007)的定义，信息安全风险评估是对信息系统及由其处理、传输和存储的信息的机密性、完整性和可用性等安全属性进行评价的过程。',
			time:'2019-10-18',
			author:'杭州网',
			vp:255 
		},

		]
	}
},
mounted(){

},
methods:{
	handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
     handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      btns(){
      	this.pageup=!this.pageup;
      }

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;
//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.tab {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    margin-bottom: 20px;
    padding: 14px 20px;
    overflow: hidden;
}
.tab .tab_nav .item {
    width: 100%;
    height: auto;
    float: left;
}
.tab .tab_nav .item .title {
    font-size: 12px;
    font-weight: 400;
    color: #6B6B6B;
    line-height: 20px;
    width: 60px;
    height: 17px;
    float: left;
    margin-right: 11px;
}
.tab .tab_nav .item .category {
    width: 1005px;
    height: auto;
    overflow: hidden;
    float: left;
}
.tab .tab_nav .item .category span {
    cursor: pointer;
    width: auto;
    font-weight: 400;
    display: inline-block;
    margin-left: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 20px;
    font-size: 12px;
    color: #3C3C3C;
    line-height: 20px;
}
.positionLink {
    color: #999999;
}
.tab .tab_nav .item > button {
    float: right;
    line-height: 22px;
    font-size: 12px;
    color: #707070;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    outline: none;
    width: 58px;
    height: 26px;
    background: #f6f6f6;
    border: 1px solid #e8e8e8;
    margin-right: 40px;
    margin-top:-5px;
}
.more {
    height: 20px !important;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
	padding:20px;
}
.consu_title{
	line-height: 32px;
    font-size: 14px;
    padding-left: 20px;
    color: #474747;
}
.contentLi {
    list-style: none;
    width: 100%;
    display: flex;
    margin-top: 22px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #E9E9E9;
}
.conten_a{
	display: flex;	 
}
.conten_a:hover img{
  transform: scale(1.08);
  transition: 0.5s all;
}
.conten_a:hover .itemTitle1{
   color:#0e82cb;
}
.itemImgBox {
    width: 148px;
    height: 84px;
    background: #EFF4F7;
}
.itemInfo {
    margin-left: 20px;
    width: 100%;
}
.itemImg {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.itemTopInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.itemCreatTime {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-right:20px;
}
.item_art{
	font-size:12px;
	color:#999;
	padding-right:10px;
}
.itemTitle1 {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
}
.itemInfoContent {
    margin-top: 8px;
    width: 688px;
    height: 56px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.vp{
	font-size:12px;
	color:#999;
	margin-top:10px;
	display: inline-block;
}
.vp i{
	padding-right:5px;
}
.pagestyle{
	float:right;
	margin-top:20px;
}
.el-input__inner{
	border:none;
}


}


</style>







